import * as React from "react";
import { Text, SpaceProps, LayoutProps, StackProps } from "@chakra-ui/react";
import VStack from "@/components/VStack";

interface HeaderProps extends SpaceProps, LayoutProps, StackProps {
  color?: string;
  centered?: boolean;
  pageTitle?: React.ReactNode;
  heading: string | React.ReactNode;
}

const Header3 = ({
  color = "black",
  spacing = 0,
  align = "flex-start",
  pageTitle,
  heading,
  width = "full",
  centered,
  children,
  ...props
}: HeaderProps) => (
  <VStack
    className="Header3"
    pos="relative"
    spacing={spacing}
    align={centered ? "center" : align}
    textAlign={centered ? "center" : undefined}
    {...props}
  >
    {pageTitle}
    <Text
      as="h1"
      color={color}
      width={width}
      mx="auto !important"
      // ml={{ lg: "-2px" }}
      textStyle="subtitle"
      transform="translateY(0.1em)"
      textAlign={centered ? "center" : undefined}
    >
      {heading}
    </Text>
    {children}
  </VStack>
);

export default Header3;
