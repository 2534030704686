import Head from "next/head";
import { useRouter } from "next/router";
import { HStack, Divider } from "@chakra-ui/react";
import LayoutDefault from "@/components/Layout/LayoutDefault";
import { BlockContainer } from "@/components/Block";
import Header from "@/components/Header3";
import FormLogin from "@/components/FormLogin";
import {
  RegisterMessage,
  ForgotPasswordMessage,
} from "@/components/HeaderMessages";
import BarEmailVerified from "@/components/BarEmailVerified";
import DefaultVStack from "@/components/VStack";
import useGetQueryStringParam from "@/hooks/useGetQueryStringParam";

function Login() {
  const router = useRouter();
  const origin = useGetQueryStringParam("origin");
  // In case the origin url has been encoded to not clash with this pages url
  // See /lib/encodeQueryString for more details
  // Only decode if value, origin of undefined would be decoded to the string 'undefined'
  const decodedOrigin = origin ? decodeURIComponent(origin) : origin;

  return (
    <LayoutDefault
      ensureNotAuthed={true}
      redirectPath="/"
      showCentered={true}
      showAsideBg={false}
    >
      <Head>
        <title>Login | Cleared Patient Portal</title>
      </Head>
      {/* if login?from=email-verified, show BarEmailVerified */}
      {router.query.from === "email-verified" && <BarEmailVerified />}

      <BlockContainer maxW="container.xs">
        {/* spacing is w3 on purpose to tighten the footer messages */}
        <DefaultVStack spacing="w3">
          {/* reset consistent spacing using pb="w1" */}
          <Header
            centered
            w="full"
            heading="Sign in to your account"
            pb="w1"
          ></Header>
          <FormLogin successPath={decodedOrigin} />
          <Divider opacity="100%" transform="translateY(2px)" />
          <HStack justifyContent="space-between">
            <RegisterMessage />
            <ForgotPasswordMessage />
          </HStack>
        </DefaultVStack>
      </BlockContainer>
    </LayoutDefault>
  );
}

export default Login;
