import { Container, Box, Text } from "@chakra-ui/react";

export default function BarEmailVerified() {
  return (
    <Box
      borderTop="1px solid"
      borderBottom={0}
      borderColor="form.good"
      bg="form.goodShade"
      py={3}
    >
      <Container
        layerStyle="mainContainer"
        maxW="container.md"
        px="insetLg"
        pb="1px"
      >
        <Text textStyle="textRg" color="form.good" textAlign="center">
          <Text as="span" fontWeight="600">
            Success!
          </Text>{" "}
          You&apos;ve verified your email.
        </Text>
      </Container>
    </Box>
  );
}
